













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ProjectTasksMutations } from '@store/modules/project-tasks/Types';
import { namespace } from 'vuex-class';
import { ITaskGroup } from "@store/modules/project-tasks/interfaces/Interfaces";

const NSProjectTasks = namespace('storeProjectTasks');


@Component({
    name: 'PopupFiltersTasks',
    components: {
        PopupFilters: () => import('@components/popups/PopupFilters.vue'),
    }
})

export default class PopupFiltersDocuments extends Vue {
    $refs!: {
        popupFilter: HTMLFormElement,
    }

    @Prop({}) activeTaskGroups!: ITaskGroup[];

    mounted() {
        this.setOptions();
    }

    popupData: any[] = [
        {
            title: 'Автор',
            block: 'author',
            type: 'select',
            key: 'author.fullName',
            options: [],
            selected: [],
        }, {
            title: 'Исполнитель',
            block: 'executor',
            type: 'select',
            key: 'executor.fullName',
            options: [],
            selected: [],
        }, {
            title: 'Создание задачи',
            titles: ['Задача создана после', 'Задача создана до'],
            block: 'creationDate',
            type: 'dateRange',
            key: 'creationDate',
            datesRange: {},
        }, {
            title: 'Крайний срок',
            titles: ['Дедлайн после', 'Дедлайн до'],
            block: 'deadline',
            type: 'dateRange',
            key: 'deadline',
            datesRange: {},
        }, {
            title: 'Статус',
            block: 'status',
            type: 'select',
            key: 'state.name',
            options: [],
            selected: [],
        },
    ];

    @NSProjectTasks.Getter('filters') filters!: any[];
    @NSProjectTasks.Getter('filtersApply') filtersApply!: boolean;
    @NSProjectTasks.Getter('groupsFilter') groupsFilter!: string;
    @NSProjectTasks.Getter('taskGroups') taskGroups!: ITaskGroup[];
    @NSProjectTasks.Mutation(ProjectTasksMutations.M_SET_TASKS_FILTERS) setFilters!: (payload: any[]) => void;
    @NSProjectTasks.Mutation(ProjectTasksMutations.M_SET_TASKS_FILTERS_APPLY) setFiltersApply!: (payload: boolean) => void;

    @Watch('groupsFilter')
    watchGroupsFilter() {
        this.updateStoreFilters();
        this.setOptions();
    }

    @Watch('filtersCount')
    watchCount() {
        if (this.filtersApply) {
            this.$emit('set-filters-preview', this.popupData);
        }
    }

    get filtersCount(): number {
        let counter: number = 0;
        for (const group of this.activeTaskGroups) {
            counter += this.getGroupFiltersCount(group);
        }
        return counter;
    }

    get activeGroupsOptions(): any {
        const output: {
            author: Set<string>,
            executor: Set<string>,
            status: Set<string>,
        } = {
            author: new Set(),
            executor: new Set(),
            status: new Set(),
        }
        if (this.activeTaskGroups.length) {
            this.activeTaskGroups?.forEach((taskGroup) => {
                taskGroup?.tasks?.forEach((task) => {
                    if (task.uuid) {
                        if (task.author?.fullName) {
                            output.author.add(task.author.fullName)
                        }
                        if (task.executor?.fullName) {
                            output.executor.add(task.executor.fullName)
                        }
                        if (task.state.name) {
                            output.status.add(task.state.name)
                        }
                    }
                })
            })
        }
        // Добавление элементов фильтра, если значение фильтра выбрано, но оно отсутствует на текущей вкладке
        if (this.filters.length) {
            for (let [key, value] of Object.entries(output)) {
                this.filters.forEach((filter) => {
                    if (filter.block === key && filter.selected.length) {
                        filter.selected.forEach((selectedFilter) => {
                            value.add(selectedFilter);
                        })
                    }
                })
            }
        }
        return output;
    }

    applyFilters() {
        this.setFiltersApply(true);
        this.updateStoreFilters();
        this.setOptions();
        this.$emit('set-filters-preview', this.popupData);
        this.closePopup();
    }

    getGroupFiltersCount(group): number {
        let tasks = group.tasks;
        this.filters.forEach((filterObject) => {
            if (filterObject.type === 'checkboxes' || filterObject.type === 'select') {
                switch (filterObject.key) {
                    case ('author.fullName'):
                        tasks = tasks.filter(task => filterObject.selected.includes(task.author.fullName));
                        break;
                    case ('executor.fullName'):
                        tasks = tasks.filter(task => filterObject.selected.includes(task.executor.fullName));
                        break;
                    case ('state.name'):
                        tasks = tasks.filter(task => filterObject.selected.includes(task.state.name));
                        break;
                }
            }
            if (
                filterObject.type === 'dateRange' &&
                (filterObject.datesRange.dateFrom || filterObject.datesRange.dateTo)
            ) {
                tasks = tasks.filter(
                    task => filterObject.datesRange.dateFrom ? Date.parse(task[filterObject.key]) >= Date.parse(filterObject.datesRange.dateFrom) : true
                );
                tasks = tasks.filter(
                    task => filterObject.datesRange.dateTo ? Date.parse(task[filterObject.key]) <= Date.parse(filterObject.datesRange.dateTo) : true
                );
            }
        })
        return tasks.length;
    }

    cancelFilters() {
        this.setFiltersApply(false);

        this.popupData.forEach((item) => {
            if (item.type === 'checkboxes' || item.type === 'select') {
                item.selected = [];
            }
            if (item.type === 'dateRange') {
                item.datesRange = {};
            }
        });

        this.$refs.popupFilter.resetFilters();
        this.setFilters([]);
    }

    setOptions() {
        this.popupData.forEach((item) => {
            if (this.activeGroupsOptions[item.block]) {
                item.options = [...this.activeGroupsOptions[item.block]]?.map(item => ({ label: item }));
            }
        })
    }

    changeSelected(e, byPreview = false) {
        for (const item of this.popupData) {
            if (item.type === 'checkboxes' && item.title === e.section) {
                item.selected = e.selected;
            }
            if (item.type === 'select' && item.title === e.section) {
                item.selected = e.selected;
            }
            if (item.type === 'dateRange' && item.title === e.section) {
                item.datesRange = e.datesRange;
            }
        }
        this.updateStoreFilters();
        if (byPreview && !e.datesRange) {
            this.$refs.popupFilter.changeSelectedData(e);
        }
        if (byPreview && e.datesRange) {
            this.$refs.popupFilter.changeSelectedDataDate(e);
        }
        this.setOptions();
    }

    updateStoreFilters() {
        const filters: any[] = [];
        this.popupData.forEach((item) => {
            if ((item.type === 'checkboxes' || item.type === 'select') && item.selected.length) {
                filters.push(item);
            }
            if (item.type === 'dateRange') {
                filters.push(item);
            }
        });
        this.setFilters(filters);
    }

    openPopup() {
        if (this.filtersApply) {
            this.$emit('set-filters-preview', this.popupData);
        }
        this.$refs.popupFilter.openPopup();
    }

    closePopup() {
        this.$refs.popupFilter.closePopup();
    }
}
